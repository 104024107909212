import React from "react";
import styled from "styled-components";
import NavbarItemView from "./CoursesNavbarItem.view";
import { CircularDiv, NavBar } from "./coursesNavbarItem.styles";

const RootParent = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0px;
  width: 100%;
  @media screen and (min-width: 600px) {
    display: none;
  }
`;
const UList = styled.ul`
  display: flex;
  padding: 0px;
  margin: auto;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

const CoursesNavbarView = ({ headerItems, activeTab, onClick }) => {
  let activeIndex = null;
  let length = Object.keys(headerItems).length;
  return (
    <>
      <RootParent>
        <NavBar>
          <UList>
            {Object.keys(headerItems).map((tabKey, i) => {
              const {
                id,
                icon,
                label,
                link,
                color,
                notification,
                isHighlighted,
              } = headerItems[tabKey];
              if (id === activeTab) {
                activeIndex = i;
              }
              return (
                <NavbarItemView
                  itemsLength={length}
                  onClick={() => onClick(tabKey)}
                  key={tabKey}
                  id={id}
                  isActive={id === activeTab}
                  icon={icon}
                  label={label}
                  link={link}
                  color={color}
                  isHighlighted={isHighlighted}
                  notification={notification}
                />
              );
            })}
            <CircularDiv />
          </UList>
        </NavBar>
      </RootParent>
    </>
  );
};

export default CoursesNavbarView;
